import React from 'react';

import { TermsOfServiceContentContainer, Title, Sub } from './TermsOfServiceStyles';
import { LinkContainer } from '../../../styles/typography';

export const TermsOfServiceContainer = () => {
  return (
    <TermsOfServiceContentContainer>
      <Title>Oqcam End User License Agreement</Title>
      <Sub>Last Updated May 1, 2024</Sub>
      <p>
        Thank you for using the products and services of Oqcam, BV. and its affiliates (jointly or individually,
        “Oqcam”, “we,” “our,” “us”).
      </p>
      <p>
        THIS OQCAM END USER LICENSE AGREEMENT (“EULA”) GOVERNS YOUR USE OF ANY PRODUCTS, SERVICES, SOFTWARE, CONTENT,
        DOCUMENTATION OR MOBILE APPLICATION PROVIDED OR MADE AVAILABLE TO YOU BY OQCAM VIA THE OQCAM WEBSITE
        (WWW.OQCAM.COM) OR MOBILE APPLICATION (COLLECTIVELY, THE “SERVICES”). BY CLICKING THE "I AGREE" BUTTON ON THE
        ACCOUNT SET-UP PAGE, OR BY ACCESSING OR USING ANY PORTION OF THE SERVICES, YOU (A) ACKNOWLEDGE THAT YOU HAVE
        READ AND UNDERSTAND THIS EULA; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER AND THAT YOU ARE ACCESSING
        THE SERVICES ON BEHALF OF AND IN YOUR CAPACITY AS AN AUTHORIZED USER OF THE COMPANY OR OTHER ENTITY FOR WHOM YOU
        WORK (“COMPANY”); AND (C) ACCEPT THIS EULA AND AGREE THAT YOU ARE LEGALLY BOUND BY THE TERMS HEREIN. IF YOU DO
        NOT ACKNOWLEDGE AND AGREE TO THE TERMS AND CONDITIONS SET FORTH HEREIN, DO NOT CLICK THE “I AGREE” BUTTON OR
        ACCESS OR USE ANY PORTION OF THE SERVICES. IT IS IMPORTANT TO READ THIS EULA CAREFULLY AS SOME OF THE PROVISIONS
        AFFECT YOUR LEGAL RIGHTS.
      </p>
      <h3>YOUR OQCAM ACCOUNT</h3>
      <p>
        In order to access and use the Services, you will need to register for an account with Oqcam (an “Oqcam
        Account”) via the account setup page on the Oqcam website. By registering, you agree that (a) all information
        you provide to us, both when you register for an Oqcam Account and when you use the Services, is and will be
        true, accurate, current and complete; (b) you will update your Oqcam Account as needed to ensure that all
        information you provide is accurate, current, and complete; (c) you will use the Services for lawful purposes
        only and subject to this EULA; and (d) you will maintain the security of your password and identification. As
        between Oqcam and you, you are responsible for the activity that happens on or through your Oqcam Account.
      </p>
      <p>
        Your Oqcam Account (including your User ID for your Oqcam Account) remains Oqcam’s property, while you have the
        right to use it as permitted by applicable law and in accordance with this EULA and the agreement between your
        Company and Oqcam.
      </p>
      <p>
        Keep the password to your Oqcam Account confidential. Due to possible security risks, we may refuse to provide
        our account retrieval service if we cannot adequately verify your credentials. We may also take measures such as
        suspending access to or terminating your Oqcam Account, either of which Oqcam may elect in its sole discretion.
      </p>
      <p>
        We may terminate your Oqcam Account and suspend your use of the Services for any reason or no reason, without
        prior notice to you, including but not limited to if we suspect that your Oqcam Account is being used in an
        unauthorized manner or if you have not used your Oqcam Account for a period of six months.
      </p>
      <h3>USING OUR SERVICES</h3>
      <p>
        Subject to this EULA, and after registering for an Oqcam Account, you may access and use the Services, including
        the Documentation, for the intended purpose in connection with your work for your Company. “Documentation” means
        any manuals, instructions, or other documents or materials that we provide to you in any form or medium and
        which describe the functionality, components, features, or requirements of the Services, including any aspect of
        the installation, configuration, integration, operation, use, support, or maintenance thereof.
      </p>
      <p>
        You will at all times comply with the applicable Documentation, the provisions of this EULA, any reasonable
        additional instructions of Oqcam, and all applicable laws and regulations. You will not:
      </p>
      <ul>
        <li>
          use the Services other than in accordance with this EULA and the intended purpose of the Services for your
          Company;
        </li>
        <li>
          use the Services in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any
          unlawful, illegal, fraudulent or harmful purpose or activity;
        </li>
        <li>
          sell, lease, commercialize, rent, lend, display, license, sublicense, transfer, assign, provide, disclose,
          publish or otherwise make available the Services, in whole or in part, to any third party;
        </li>
        <li>
          copy, modify, or create derivative works of the Services, in whole or in part, or access or use the Services
          to create a competitive product;
        </li>
        <li>
          reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any
          software component of the Services, in whole or in part, including any user interface techniques or algorithms
          of the Services by any means;
        </li>
        <li>
          take any action that would cause Oqcam’s proprietary software or source code to be placed in the public
          domain; or
        </li>
        <li> work around any technical limitation in the Services.</li>
      </ul>
      <p>
        You represent and warrant that (a) you own or control all of the rights to the data, including designs and other
        content, that you upload to the Services (or otherwise make available to us), or you otherwise have the right to
        make available such data via the Services, (b) the foregoing data does not infringe or otherwise violate any
        intellectual property or other proprietary rights of any third parties, and (c) Oqcam’s use of such data for the
        purpose of providing the Services will not infringe or otherwise violate any intellectual property or other
        proprietary rights of any third parties.
      </p>
      <p>
        You warrant and covenant that you will not use the Services for any illegal or improper activities, including,
        but not limited to: uploading or otherwise transmitting any information or content that would compromise or
        endanger the security of the network, violate applicable law, or infringe or violate any privacy or other rights
        of any other party.
      </p>
      <p>
        We reserve the right to modify, terminate and suspend the operation of the Services, as well as the provision of
        any or all Services, without notice or liability, at any time and for any or no reason in our sole and absolute
        discretion. To the extent permitted by applicable law, we may also take such measures as deleting relevant
        information and content, restricting the use of or taking back your Oqcam Account, and holding you legally
        liable as the case may be.
      </p>
      <h3>INTELLECTUAL PROPERTY</h3>
      <p>
        As between Oqcam and you, Oqcam exclusively owns all right, title, and interest in the Services, including our
        proprietary software, Documentation, content, and any hardware leased by Oqcam for access to and use of the
        Services (“Hardware”), including any new versions, updates, customizations, enhancements, modifications and
        improvements to the foregoing, and all intellectual property rights, worldwide, in the foregoing. Nothing in
        this EULA shall convey to you any ownership of any intellectual property in the Services or components thereof.
      </p>
      <p>
        You agree not to remove or modify in any way any proprietary markings, including any patent, trademark,
        copyright or confidentiality notices in or on the Services or components thereof, or visible during its
        operation. You will incorporate or reproduce such proprietary markings in any permitted back-up copies or other
        copies.
      </p>
      <h3>OQCAM MARKS AND OQCAM MATERIALS</h3>
      <p>
        Oqcam’s name, Oqcam™, Oqcam logos and any other Oqcam product or service name or slogan included in the Services
        (collectively, “Oqcam Marks”) are trademarks of Oqcam and may not be copied, imitated, or used, in whole or in
        part, without the prior written permission of Oqcam. You may not use any metatags or any other “hidden text”
        utilizing “Oqcam” or any other Oqcam Mark without our prior written permission.
      </p>
      <p>
        All other trademarks, product names, brands, company names or logos mentioned in the Services are the property
        of their respective owners. Reference to any other products, services, processes, or other information, by trade
        name, trademark, manufacturer, supplier or otherwise, does not constitute or imply Oqcam’s endorsement,
        sponsorship or recommendation.
      </p>
      <p>
        Subject to the rights expressly set forth in this EULA, Oqcam and its licensors own all right, title, and
        interest in and to the Services, the Oqcam Marks, the look and feel of the Services (including, but not limited
        to, all page headers, custom graphics, button icons, and scripts) and all content and other materials contained
        on the Services (including, but not limited to, all designs, text, graphics, pictures, information and data
        (collectively, the “Oqcam Materials”) together with any and all intellectual property rights, worldwide,
        embodied therein or related thereto.
      </p>
      <h3>LEARNING FROM USE OF THE SERVICES</h3>
      <p>
        Our systems may analyze your data and use de-identified or aggregated information generated from your use of the
        Services to provide you and other customers with improved products and Services. By using the Services, you
        understand that Oqcam will use your data and de-identified or aggregated information for that purpose.
      </p>
      <h3>PRIVACY</h3>
      <p>
        Please see our privacy policy for information about how we protect, collect, process, use, and disclose personal
        information about users of the Services, including for product development and improvement purposes.
      </p>
      <h3>FEEDBACK</h3>
      <p>
        In the event you elect, in connection with your use of the Services, to provide any feedback, suggestions,
        ideas, modifications and/or improvements to the Services, including new features or functionalities relating
        thereto (collectively, “Feedback”), you hereby grant to Oqcam and its affiliates a worldwide, perpetual,
        irrevocable, sublicensable, royalty-free, fully paid-up nonexclusive license, to use, disclose, copy, modify,
        perform, display and otherwise exploit such Feedback, including intellectual property therein, for any purpose.
      </p>
      <h3>OUR WARRANTIES AND DISCLAIMERS</h3>
      <p>
        THE SERVICES ARE PROVIDED “AS IS.” TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT MAKE ANY
        REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, CONCERNING ANY MATTER UNDER THIS EULA AND WE DISCLAIM ALL
        WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE. OQCAM SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
        ACCURACY OR COMPLETENESS, FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, AND NON-INFRINGEMENT, AND ALL
        WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE. WE MAKE NO WARRANTY OF ANY KIND THAT THE SERVICES,
        ANY COMPONENTS THEREOF, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET YOUR OR ANY OTHER PERSON'S
        REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY
        SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.
      </p>
      <h3>LIMITATION OF LIABILITY</h3>
      <p>
        IN NO EVENT WILL OQCAM OR ITS LICENSORS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY,
        PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES, OR LOST REVENUES OR PROFITS, ARISING OUT OF OR RELATED TO YOUR USE
        OF THE SERVICES OR THIS EULA, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER
        OR NOT OQCAM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW FOR
        THE EXCLUSION OF DAMAGES, THE LIABILITY OF OQCAM IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT
        PERMITTED BY THE LAWS OF SUCH JURISDICTION.
      </p>
      <h3>INDEMNIFICATION</h3>
      <p>
        You agree to indemnify, defend and hold harmless Oqcam and its current, future, and former officers, directors,
        employees, agents, licensors, and contractors, from and against all losses, expenses, damages, fines, penalties
        and other costs, including reasonable attorneys’ fees and costs, arising out of or related to: (a) your use or
        misuse of the Services or the Oqcam Materials; (b) your breach of this EULA; and (c) any materials submitted by
        you to the Services. Oqcam reserves the right to assume the exclusive defense and control of any matter that is
        subject to indemnification under this section, in which event you agree to cooperate with any reasonable
        requests assisting our defense of such matter.
      </p>
      <h3>THIRD PARTY SERVICES</h3>
      <p>
        The Services may include links to or plug-ins of third-party websites, apps or services that may be operated by
        companies not affiliated with Oqcam. The inclusion of such links or plug-ins with the Services is provided
        solely as a convenience to you, and Oqcam makes no representations or warranties regarding any such third-party
        website, app or service (each, a “Third-Party Service”). You understand that clicking on any link to or plug-in
        of a third-party website, app or service, connects you directly to the applicable Third-Party Service, and
        subjects you to any terms, policies and privacy practices of such third party. OQCAM IS NOT RESPONSIBLE FOR THE
        SUBSTANCE OR ACCESSIBILITY OF ANY THIRD PARTY SERVICES ACCESSIBLE THROUGH THE OQCAM SERVICES AND YOU BEAR ALL
        RISKS ASSOCIATED WITH ACCESS TO AND/OR USE OF SUCH CONTENT.
      </p>
      <h3>MOBILE APPLICATION</h3>
      <p>
        If you are using Oqcam’s mobile application (the “App”), you agree to use the App in accordance with this EULA
        and any applicable third party terms, rules or agreements, such as those published by your third-party mobile
        phone or tablet operating system, wireless data service provider, or the third party from whom you are
        downloading the App using Apple App Store or Google Play, as applicable. You are responsible for reviewing the
        applicable Apple App Store or Google Play terms and conditions and complying with such terms and conditions
        while using the App. If you object to any of the terms and conditions, your sole recourse shall be to cease
        using the App. Without limiting the foregoing, if you are downloading the App through Apple App Store for use on
        an Apple-branded product, you agree to comply with the Licensed Application End User License Agreement set forth
        in the Apple Media Services Terms and Conditions, which can be accessed and downloaded at:
        https://www.apple.com/legal/internet-services/Apple App Store/us/terms.html (“Apple Terms of Service"). If there
        are any conflicts between this EULA and the Apple Terms of Service, the Apple Terms of Service will apply. You
        acknowledge and agree that:
      </p>
      <ul>
        <li>
          Acknowledgment of Parties; Third Party Beneficiary. This EULA is made between you and Oqcam, and that Oqcam,
          not Apple Inc. ("Apple"), is responsible for the App and its content. Notwithstanding the foregoing, Apple and
          Apple's subsidiaries are third party beneficiaries of this EULA and, upon your acceptance of this EULA, will
          have the right (and will be deemed to have accepted the right) to enforce this EULA against you as a third
          party beneficiary hereof. Oqcam’s right to enter into, rescind, or terminate any variation, waiver or
          settlement under this EULA is not subject to the consent of any third party.
        </li>
        <li>
          Scope of the License. The license you have been granted in this EULA is limited to a non-transferable license
          to use the App on any Apple-branded product that you own or control and as permitted by the Usage Rules set
          forth in Apple's App Store Terms of Service, except that the App may be accessed and used by other accounts
          associated with your Apple App Store account via Family Sharing or volume purchasing.
        </li>
        <li>
          Maintenance and Support. Apple has no obligation whatsoever to furnish any maintenance or support services
          with respect to the App.
        </li>
        <li>
          Warranty. To the maximum extent permitted by applicable law, you agree that your recourse for warranty failure
          in connection with the App pursuant to this EULA shall be through Oqcam, and Apple will have no other warranty
          obligation whatsoever with respect to the App, nor be responsible for any other claims, losses, liabilities,
          damages, costs, or expenses attributable to any failure to conform to any warranty.
        </li>
        <li>
          Product Claims. You agree that your recourse for product claims arising from the App shall be through Oqcam,
          and that Apple is not responsible for addressing any claims from you or any third party relating to the App or
          your possession and/or use of the App, including, but not limited to: (i) product liability claims; (ii) any
          claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims
          arising under consumer protection or similar legislation.
        </li>
        <li>
          Intellectual Property Rights. In the event of any third party claim that the App or your possession and use of
          the App infringes that third party's intellectual property rights, you agree that your recourse under this
          EULA will be through Oqcam and that Apple will not be responsible for the investigation, defense, settlement
          and discharge of any such infringement claim.
        </li>
        <li>
          Legal Compliance. You represent and warrant that (i) you are not located in a country that is subject to a
          U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting”
          country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
        </li>
      </ul>
      <h3>TERM AND TERMINATION</h3>
      <p>
        This EULA will take effect from the earlier of: the date that you accept this EULA or the date that you begin
        using any part of the Services, and will continue in force until your Oqcam Account is deactivated by either you
        or Oqcam, which either party may elect to exercise with or without cause and in its sole discretion. Upon any
        such deactivation or suspension, your right to use the Services will immediately cease and you must discontinue
        use of the Services. Oqcam reserves the right to immediately and permanently deactivate your Oqcam Account and
        delete any information or content stored through your Oqcam Account. Notwithstanding the foregoing, any term or
        provision of this EULA that by its nature is intended to survive termination of this EULA or your use or access
        to the Services will survive such termination, including intellectual property ownership, use of Feedback,
        disclaimer of warranties, limitation of liability, and indemnification.
      </p>
      <h3>GENERAL PROVISIONS</h3>
      <p>
        Entire Agreement. This EULA (together with our Privacy Policy and any other terms and conditions incorporated by
        reference) set forth the entire agreement and understanding between us and you relating to the subject matter
        hereof and supersede any and all prior or contemporaneous agreements, proposals, negotiations, conversations,
        discussions and understandings, written or oral, with respect to such subject matter and all past dealing or
        industry custom.
      </p>
      <p>
        Assignment of Rights. You may not assign or transfer any of your rights or obligations under this EULA without
        the prior written consent of Oqcam, which Oqcam may withhold in its sole discretion. Oqcam may assign or
        transfer any of its rights or obligations under this EULA, in whole or in part, to any third party, in Oqcam’s
        sole discretion. Modifications to this EULA. Oqcam reserves the right to change or modify any of the terms and
        conditions contained in this EULA (or any policy or guideline of Oqcam) at any time and in its sole discretion.
        Oqcam may provide notice of such modifications by: (a) sending an email, (b) posting a notice on the Oqcam
        website, (c) posting the revised Terms on the Oqcam website and revising the date at the top of this EULA or by
        such other form of notice as determined by Oqcam ((a) – (c), each constitute “Notice”). Any changes or
        modification will be effective 14 days after we provide Notice. Your continued use of the Services following the
        14-day Notice period will constitute your acceptance of such changes or modifications
      </p>
      <p>
        Severability. If any provision of this EULA is invalid, illegal or unenforceable in any jurisdiction, such
        invalidity, illegality or unenforceability shall not affect any other term or provision of this EULA.
      </p>
      <p>
        Electronic Communications. Oqcam may choose to electronically deliver all communications with you, which may
        include email to the email address you provide to us. Oqcam’s electronic communications to you may transmit or
        convey information about action(s) taken in accordance with your request, portions of your request that may be
        incomplete or require additional explanation, any notices required under applicable law and any other notices.
        You agree to do business electronically with Oqcam, and to receive electronically all current and future
        notices, disclosures, communications and information. An electronic notice will be deemed to have been received
        on the day of receipt as evidenced by such email. We may also use electronic signatures and obtain them from
        you.
      </p>
      <p>
        Governing Law and Venue. This EULA shall be governed by and construed in accordance with the laws of Delaware,
        USA without giving effect to any choice or conflict of law provision or rule that would require or permit the
        application of the laws of any other jurisdiction. The parties hereto submit to the jurisdiction of the
        competent courts of Delaware, USA.
      </p>
      <p>
        Interpretation. Except where the context otherwise requires, the use of the term “including” and inflections
        thereof, mean “including without limitation,” “include without limitation” or “includes without limitation.”
      </p>
      <p>
        Trade Compliance. Customer shall comply with all applicable laws, regulations, and rules as the
        Exporter/Importer of Record. Customer shall not export, import, re-export, or otherwise transmit, directly or
        indirectly, any Equipment or Software except in full compliance with all US, EU, and other applicable export,
        import, control laws and regulations. These obligations shall survive the termination of the Agreement. Further,
        Customer agrees the product(s), software, technology/technical data, and/or services will not be used for any
        purposes, to include design, production, assembly, testing, operation, integration, installation, inspection,
        maintenance, repair, overhaul, or refurbishment, related to a military or defense application or military
        End-Use or by a military End-User in accordance with 15 CFR 744.21 Supplement 2, and 22 CFR 126.1. The
        product(s), software, technology/technical data and/or services acquired from Oqcam will not be re-exported,
        sold or otherwise re-sold or transferred to a destination subject to UN, EU or OSCE embargo where that act would
        be in breach of the terms of that embargo sold, or transferred in violation of Council Regulation (EC) No
        2021/821 setting up a Community regime for the control of exports, transfer, brokering and transit of dual-use
        items. Customer will not sell, transfer, export or re-export any items received from Oqcam for use in activities
        that involve nuclear explosive activities, unsafeguarded nuclear activities, nuclear fuel cycle or nuclear
        propulsion activities, or in the design, development, production, stockpiling, or use of chemical weapons,
        biological weapons, missiles, rocket systems or unmanned aerial vehicles (UAV). In the event the Customer
        provides product(s), software, technology/technical data export-controlled (e.g., International Traffic in Arms
        Regulations, Export Administration Regulations) into the services, Customer shall provide prompt written notice
        to Oqcam. Customer acknowledges and agrees that the responsibility for export-controlled items shall remain with
        the Customer.
      </p>
      <p>
        No Third Party Beneficiaries. This EULA controls the relationship between Oqcam and you. They do not create any
        third party beneficiary rights, except to the extent expressly indicated herein.
      </p>
      <p>
        Waiver. No delay or failure of you or Oqcam in exercising any right herein and no partial or single exercise
        thereof shall be deemed of itself to constitute a waiver of such right or any other rights herein. Any waiver by
        you or Oqcam of any breach of any provision of this EULA shall not operate or be construed as a waiver of any
        subsequent or other breach.
      </p>
      <h3>HOW TO CONTACT US</h3>
      <p>
        If you have questions or communications about this EULA, please contact us at{' '}
        <LinkContainer link="/contact-us/">https://www.oqcam.com/contact-us</LinkContainer>.
      </p>
    </TermsOfServiceContentContainer>
  );
};
